/**
 * We will use use the regular onClick handler as a replacement for onPress until
 * having a decent fix for those issues:
 * @see https://github.com/necolas/react-native-web/issues/731
 * @see https://github.com/necolas/react-native-web/issues/1219
 */

import { HTMLAttributes } from 'enzyme';
import {
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
} from 'react';

export interface Props extends PropsWithChildren<HTMLAttributes> {
  onClick?: MouseEventHandler<HTMLDivElement>;
  onPress?: MouseEventHandler<HTMLDivElement>;
  onMouseEnter?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  activeOpacity?: number;
  accessibilityLabel?: string;
  testID?: string;
}

const Touchable = ({
  children,
  onPress,
  onClick,
  accessibilityLabel,
  testID,
  ...touchableOpacityProps
}: Props): ReactElement => {
  const touchableProps: Props = {
    ...touchableOpacityProps,
    // @ts-ignore web specific
    onClick(event: MouseEventHandler): void {
      if (touchableOpacityProps.disabled) return;

      // @ts-ignore will take precedence when defined over onPress
      if (onClick) onClick(event);

      // @ts-ignore fall back to onPress
      if (onPress) onPress();
    },
  }

  return (
    <div
      style={{ cursor: 'pointer' }}
      aria-disabled={touchableProps.disabled} 
      data-testid={testID}
      aria-label={accessibilityLabel} 
      {...touchableProps}
    >
      {children}
    </div>
  );
};

export default Touchable;
