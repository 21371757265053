import isEmpty from 'lodash/isEmpty';
import { FC, ReactElement, useState } from 'react';

import Badge from '../Badge';
import Symbol from '../Symbol';
import Text from '../Text';
import { Color, TextStyle } from '../theme';
import {
  colorAssociation,
  SMALL_SYMBOL_SCALE,
  MEDIUM_SYMBOL_SCALE,
  LARGE_SYMBOL_SCALE, DEFAULT_COLOR
} from './helper';
import {
  BadgeContainer,
  HelperText,
  TextContainer,
  SymbolContainer, RowWrapper, RowsContainer
} from './styledComponents';
import { ButtonProps } from './typings';
import MuiButton from '@mui/material/Button';
import colors from '@legacy-components/theme/colors';

const ButtonWithHelperText: FC<ButtonProps> = ({
  helperText,
  title,
  fullWidth = false,
  color = DEFAULT_COLOR,
  symbolKey,
  tiny = false,
  small = false,
  large = false,
  outlined = false,
  inGroupRight = false,
  inGroupLeft = false,
  inGroupCenter = false,
  cappedHeight = false,
  notificationCounter,
  testID,
  onPress,
  onClick,
  width,
  ...touchableContainerProps
}: ButtonProps): ReactElement => {
  let symbolScale: number = small ? SMALL_SYMBOL_SCALE : MEDIUM_SYMBOL_SCALE;
  symbolScale = large ? LARGE_SYMBOL_SCALE : symbolScale;
  const symbolOnly: boolean = !!symbolKey && isEmpty(title);
  const { textColor, backgroundColor } = colorAssociation({
    color,
    disabled: touchableContainerProps.disabled,
    light: touchableContainerProps.light,
  });

  const [isHovered, setIsHovered] = useState(false);

  let size: 'small' | 'large' | 'medium' = 'large';
  if (tiny) {
    size = 'small'
  }
  if (small) {
    size = 'medium';
  }

  return (
    <MuiButton
      variant={outlined ? 'outlined' : 'contained'}
      data-testid={testID}
      onClick={onClick ?? onPress}
      fullWidth={fullWidth}
      size={size}
      sx={{
        borderColor: colors[textColor as Color],
        backgroundColor: colors[backgroundColor],
        width,
        height: '100%',
        boxSizing: 'border-box',
        '&:hover': {
          backgroundColor: colors[backgroundColor],
        }
      }}
      disabled={touchableContainerProps.disabled}
      onMouseEnter={(): void => setIsHovered(true)}
      onMouseLeave={(): void => setIsHovered(false)}
    >
      <RowsContainer>
        <RowWrapper hidden={isHovered}>
          {notificationCounter && notificationCounter > 0 && (
            <BadgeContainer>
              <Badge counter={notificationCounter} />
            </BadgeContainer>
          )}
          {symbolKey && (
            <SymbolContainer symbolOnly={symbolOnly} large={large} small={small}>
              <Symbol
                symbolKey={symbolKey}
                scale={symbolScale}
                color={textColor as Color}
              />
            </SymbolContainer>
          )}
          <TextContainer>
            {title && (
              <Text styleKey={TextStyle.BUTTON_LABEL} color={textColor as Color}>
                {title}
              </Text>
            )}
            <HelperText styleKey={TextStyle.BODY_3} color={textColor as Color}>
              {helperText}
            </HelperText>
          </TextContainer>
        </RowWrapper>
        <RowWrapper hidden={!isHovered}>
          <HelperText styleKey={TextStyle.BUTTON_LABEL} color={textColor as Color} bold>
            {helperText}
          </HelperText>
        </RowWrapper>
      </RowsContainer>
    </MuiButton>
  );
};

ButtonWithHelperText.defaultProps = {
  color: Color.PRIMARY,
};

export default ButtonWithHelperText;
